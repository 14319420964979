<template>
  <div class="modal fade" id="modal-transfer" tabindex="-1"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title fw-bold">Transfer Token</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <div class="mb-3">
            <label class="form-label fs-6">Recipient address</label>
            <input type="text" class="form-control" v-model="transferTo" id="modal-transfer-address">
            <small id="modal-transfer-error" class="text_min">{{ globalError }}</small>
          </div>
        </div>

        <div class="modal-footer text-center justify-content-center">
          <span class="d-flex justify-content-center w-100"><strong>Items sent to the wrong address cannot be recovered</strong></span>

          <button class="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2" id="modal-btn-transfer" type="button" @click="transferToken()">
            <template v-if="!processing">Transfer</template>
            <template v-else><div class="ld ld-ring ld-spin"></div></template>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalTransfer',
  data () {
    return {
      transferTo: ""
    }
  },
  props: {
    processing: Boolean,
    globalError: String
  },
  methods: {
    transferToken() {
      this.$emit('transferToken', this.transferTo)
    }
  }
}
</script>
