<template>
  <main class="main">
    <div class="section pb-0" v-if="item && collection">
      <div class="container">
        <div class="row g-3 mt-2 mb-5">
          <div class="col-12">
            <div class="card mt-5">
              <div class="card-body">
                <div class="row g-3">
                  <div class="col-lg-4 col-md-12">
                    <img class="img-fluid rounded-3 w-100 collection-image" :src="item.image_file" alt="title">

                    <hr>

                    <h4 class="text-primary">Properties</h4>
                    <div class="tab-pane fade show active" id="Properties" role="tabpanel" aria-labelledby="Properties-tab">
                      <div class="row g-2">

                        <div class="col-md-6" v-for="attribute in item.metadata.attributes" v-bind:key="attribute.trait_type">
                          <a href="#" class="d-flex flex-column border border-dashed p-3">
                            <h5>
                              {{ attribute.trait_type }}:<br><span class="text-primary">{{ attribute.value }}</span>
                              <br>
                              <span
                                class="text-muted"> ({{
                                  parseFloat(collection.trait_data[attribute.trait_type][attribute.value] / collection.total_items * 100).toFixed(2)
                                }}%)</span>
                            </h5>

                          </a>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 col-md-12">
                    <div class="px-lg-4">
                      <div class="mb-0">
                        <div class="row">
                          <div class="col-md-6">
                            <h3>
                              <strong>{{ item.metadata.name }}</strong>
                            </h3>
                          </div>
                          <div class="col-md-6">
                            <template v-if="item.last_owner == this.$store.getters.currentAccount">
                              <button
                                class="d-inline-flex align-items-center justify-content-center border border-primary py-2 px-4 rounded-pill ct-btn m-1 pull-right"
                                v-on:click="cancelItemListing"
                                v-if="item.status != 'not_for_sale'"
                              >
                                Cancel listing
                              </button>
                              <button
                                class="d-inline-flex align-items-center justify-content-center border border-primary py-2 px-4 rounded-pill ct-btn m-1 pull-right"
                                data-bs-toggle="modal" data-bs-target="#modal-modify-list"
                                v-if="item.status != 'not_for_sale'"
                              >
                                Modify listing
                              </button>
                              <button
                                class="d-inline-flex align-items-center justify-content-center border border-primary py-2 px-4 rounded-pill ct-btn m-1 pull-right"
                                data-bs-toggle="modal" data-bs-target="#modal-list"
                                v-if="item.status == 'not_for_sale'"
                              >
                                List for sale
                              </button>
                              <button
                                class="d-inline-flex align-items-center justify-content-center border border-primary py-2 px-4 rounded-pill ct-btn m-1 pull-right"
                                data-bs-toggle="modal" data-bs-target="#modal-transfer"
                                v-if="item.status == 'not_for_sale'"
                              >
                                <template v-if="!processing">Transfer</template>
                                <template v-else>
                                  <div class="ld ld-ring ld-spin"></div>
                                </template>
                              </button>
                            </template>
                          </div>
                        </div>
                      </div>

                      <router-link :to="{ name: 'Collection', params: { collection: item.collection_id }}" class="card-product-collection mb-2 d-block">
                        <p class="d-flex align-items-center mb-2">
                          {{ item.collection_name }}
                          <span class="ms-2" v-if="item.collection_verified"><i class="bi bi-check-circle-fill color-light-success"></i></span>
                        </p>
                      </router-link>

                      <div class="row">
                        <div class="col-md-7">

                          <h4 class="text-primary">About collection</h4>
                          <div class="mt-3 border border-dashed p-3">
                            <p>{{ this.collection.description }}</p>
                          </div>

                          <hr>

                          <div class="card">
                            <div class="card-body">
                              <ul class="nav nav-tabs tab-body-header rounded d-inline-flex" id="myTab" role="tablist">

                                <li class="nav-item" role="presentation">
                                  <button class="nav-link active" id="History-tab" data-bs-toggle="tab" data-bs-target="#History" type="button" role="tab" aria-controls="History" aria-selected="true">History
                                  </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <button class="nav-link" id="Offers-tab" data-bs-toggle="tab" data-bs-target="#Offers" type="button" role="tab" aria-controls="Offers" aria-selected="false">Offers
                                  </button>
                                </li>

                              </ul>

                              <div class="tab-content mt-4" id="myTabContent">
                                <div class="tab-pane fade px-3 show active" id="History" role="tabpanel" aria-labelledby="History-tab">

                                  <div v-for="(historyEvent) in history" v-bind:key="historyEvent.id"
                                       class="row align-items-center border-bottom py-2">
                                    <div class="col text-truncate">
                                      <div class="d-flex align-items-center">
                                        <img src="@/assets/images/author/avatar3.svg" data-bs-toggle="tooltip" title=""
                                             alt="Avatar" class="rounded-circle avatar"
                                             data-original-title="Avatar Name" data-bs-original-title="">
                                        <div class="ms-3">
                                          <div><span>{{ historyEvent.event_type }}</span></div>
                                          <div><span>{{ historyEvent.event_data }}</span></div>
                                          <p class="mb-0 small text-muted">{{ historyEvent.network_timestamp | formatDate }}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-auto">
                                      <h5 class="mb-0"></h5>
                                      <p class="mb-0 small text-end text-muted"></p>
                                    </div>
                                  </div>

                                </div>
                                <div class="tab-pane fade" id="Offers" role="tabpanel" aria-labelledby="Offers-tab">

                                  <div class="row align-items-center border-bottom py-2"  v-for="(offer) in item.offers" v-bind:key="offer.id">
                                    <div class="col text-truncate">
                                      <div class="d-flex align-items-center">
                                        <img src="@/assets/images/author/avatar3.svg" data-bs-toggle="tooltip" title="" alt="Avatar" class="rounded-circle avatar" data-original-title="Avatar Name" data-bs-original-title="">
                                        <div class="ms-3">
                                          <div><span>Offer created by</span>
                                            <span title="" class="fw-bold"> {{offer.event_data.args.offerer | toShortAddr}}</span>
                                            <span class="text-muted" v-if="offer.event_data.args.offerer == web3Modal.account"> (You)</span>
                                          </div>

                                          <p class="mb-0 small text-muted">{{offer.network_timestamp | formatDate}}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-auto">
                                      <h5 class="mb-0">{{offer.event_data.args.offerAmount|toBNB}} BNB</h5>
                                      <p class="mb-0 small text-end text-muted">{{offer.event_data.args.expireTime}}</p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <template v-if="item.status == 'listed'">
                            <div class="row">
                              <div class="col-md-6">
                                <span class="lead mt-3 d-block">Current Price</span>
                                <div class="card-porduct-price d-flex align-items-center mb-3 color-price-up">
                                  <h4 class="d-flex align-items-center">
                                    {{item.current_asking_price|toBNB}} BNB
                                  </h4>
                                </div>
                              </div>
                              <div class="col-md-6" v-if="item.highest_offer">
                                <span class="lead mt-3 d-block">Highest Offer</span>
                                <div class="card-porduct-price d-flex align-items-center mb-3 color-price-up">
                                  <h4 class="d-flex align-items-center">
                                    {{item.highest_offer, 0|toBNB}} BNB
                                  </h4>
                                </div>
                              </div>
                            </div>

                            <div class="d-flex align-items-top flex-wrap" v-if="item.last_owner != this.$store.getters.currentAccount">
                              <div class="pe-3">
                                <a href="#"
                                   class="d-inline-flex align-items-center justify-content-center border border-primary py-2 px-4 rounded-pill ct-btn m-1"
                                   data-bs-toggle="modal" data-bs-target="#modal-buy"
                                >
                                   <span class="me-2">
                                     <img src="@/assets/images/svg/cart.svg" alt="">
                                   </span>
                                  BUY
                                </a>

                                <a style="height: 48px;" href="#"
                                   class="d-inline-flex align-items-center justify-content-center border border-primary py-2 px-4 rounded-pill ct-btn m-1"
                                   data-bs-toggle="modal" data-bs-target="#modal-offer"
                                >
                                   <span class="me-2">
                                     <i class="icofont-sale-discount fs-6" style="color: #feb019;"></i>
                                   </span>
                                  MAKE OFFER
                                </a>
                              </div>
                            </div>
                          </template>
                          <template v-else-if="item.status == 'auctioned'">

                            <span class="lead mt-3 d-block">Current Price</span>
                            <div class="card-porduct-price d-flex align-items-center mb-3 color-price-up">
                              <h4 class="d-flex align-items-center">
                                 <span class="me-1">
                                    <i class="bi bi-currency-bitcoin color-price-up"></i>
                                 </span>
                                0.00 BNB
                              </h4>
                              <h4 class="ms-2">≈0.00</h4>
                            </div>

                            <div class="d-flex align-items-top flex-wrap">
                              <div class="pe-3">
                                <span class="lead">Auction ending in</span>
                                <h2 class="mb-3 color-price-down" id="bidtime1"> 15:27:31</h2>
                                <a href="#" data-bs-toggle="modal" data-bs-target="#walletconnect" class="d-inline-flex align-items-center justify-content-center border border-primary py-2 px-4 rounded-pill ct-btn m-1">
                                   <span class="me-2">
                                     <img src="@/assets/images/svg/bid.svg" alt="">
                                   </span>
                                  PLACE BID
                                </a>

                                <a href="bid.html" class="d-inline-flex align-items-center justify-content-center border border-primary py-2 px-4 rounded-pill ct-btn m-1">
                                   <span class="me-2">
                                     <img src="@/assets/images/svg/cart.svg" alt="">
                                   </span>
                                  BUY NOW
                                </a>
                              </div>
                            </div>
                          </template>

                          <template v-else>
                            <a style="height: 48px;" href="#"
                               class="d-inline-flex align-items-center justify-content-center border border-primary py-2 px-4 rounded-pill ct-btn m-1"
                               data-bs-toggle="modal" data-bs-target="#modal-offer"
                            >
                               <span class="me-2">
                                 <i class="icofont-sale-discount fs-6" style="color: #feb019;"></i>
                               </span>
                                MAKE OFFER
                            </a>
                          </template>

                          <hr>

                          <div class="mt-4 ps-lg-12">
                            <div class="d-flex align-items-center ps-0">
                              <img src="@/assets/images/author/avatar1.svg" data-bs-toggle="tooltip" title="" alt="Avatar" class="rounded-circle img-thumbnail avatar lg"
                                   data-original-title="Avatar Name" data-bs-original-title="">
                              <div class="ms-3">
                                <a href="#" title="">Owner</a>
                                <p class="mb-0 fw-bold">
                                  {{ item.last_owner | toShortAddr }}
                                  <span class="text-muted" v-if="item.last_owner == this.$store.getters.currentAccount">(You)</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row g-3">
          <div class="col-12">

          </div>
        </div>
      </div>

      <ModalOffer :globalError="globalError" :processing="processing" :wrappedNativeAllowance="wrappedNativeAllowance" @createOffer="createOffer($event)" @approveWrappedNative="approveWrappedNative($event)"></ModalOffer>
      <ModalList :globalError="globalError" :processing="processing" :approved="approved" @listToken="listToken($event)" @approveToken="approveToken($event)"></ModalList>
      <ModalModifyList :globalError="globalError" :processing="processing"  @modifyListing="modifyListing($event)"></ModalModifyList>
      <ModalBuy :globalError="globalError" :processing="processing" :item="item" @buyToken="buyToken($event)"></ModalBuy>
      <ModalTransfer :globalError="globalError" :processing="processing" @transferToken="transferToken($event)"></ModalTransfer>
    </div>
  </main>
</template>


<script>
import {getERC721Contract, getMarketplaceContract, getWrappedNativeContract} from "@/utils/contract";
import ModalModifyList from "@/components/ModalModifyList";
import ModalTransfer from "@/components/ModalTransfer";
import ModalOffer from "@/components/ModalOffer";
import ModalList from "@/components/ModalList";
import ModalBuy from "@/components/ModalBuy";
import {mapState} from "vuex";
import axios from "axios"
import Web3 from "web3";


const marketplace = getMarketplaceContract()
const wrappedNative = getWrappedNativeContract()


export default {
  name: "CollectionItem",
  components: {
    ModalModifyList,
    ModalTransfer,
    ModalOffer,
    ModalList,
    ModalBuy
  },
  computed: {
    ...mapState(['web3Modal'])
  },
  methods: {
    createOffer(offerAmount) {
      this.processing = true;
      this.globalError = null;

      offerAmount = this.web3Modal.web3.utils.toWei(offerAmount.toString())

      const signer = marketplace.connect(this.web3Modal.signer)
      signer["createOffer"](this.item.collection_address, this.item.token_id, offerAmount, 1695317649)
        .then((tx) => {
          return tx.wait().then(() => {
            this.processing = false
          }, (error) => {
            this.processing = false
            this.globalError = error
            console.log(error)
          });
        })
        .catch((error) => {
          this.processing = false
          console.log(error)
        })
    },
    approveWrappedNative(offerAmount) {
      this.processing = true;
      this.globalError = null;

      const signer = wrappedNative.connect(this.web3Modal.signer)
      signer["approve"](process.env.VUE_APP_MARKETPLACE_ADDR, "1000000000000000000000")
        .then((tx) => {
          return tx.wait().then(() => {
            this.processing = false
            this.wrappedNativeAllowance = offerAmount
          }, (error) => {
            this.processing = false
            this.globalError = error
            console.log(error)
          });
        })
        .catch((error) => {
          this.processing = false
          console.log(error)
        })
    },
    transferToken(transferToAddress) {
      this.processing = true;
      this.globalError = null;

      const signer = this.collectionContract.connect(this.web3Modal.signer)
      signer["safeTransferFrom(address,address,uint256)"](this.$store.getters.currentAccount, transferToAddress, this.item.token_id)
        .then((tx) => {
          return tx.wait().then(() => {
            this.processing = false
            this.ownerOf = transferToAddress
            $('#modal-transfer').modal('hide')
          }, (error) => {
            this.processing = false
            this.globalError = error
            console.log(error)
          });
        })
        .catch((error) => {
          this.globalError = "Invalid recipient address"
          this.processing = false
          console.log(error)
        });
    },
    modifyListing() {

    },
    wrapToken() {
      this.processing = true;
      this.globalError = null;

      const signer = this.collectionContract.connect(this.web3Modal.signer)
      signer["safeTransferFrom(address,address,uint256)"](this.$store.getters.currentAccount, process.env.VUE_APP_DEGENPUNKS_ADDR, this.tokenId)
        .then((tx) => {
          return tx.wait().then(() => {
            this.processing = false
            this.collectionAddress = process.env.VUE_APP_DEGENPUNKS_ADDR
            this.oldDegen = false
            this.collectionContract = getERC721Contract(process.env.VUE_APP_DEGENPUNKS_ADDR)
            this.$router.push({
              name: 'CollectionItem',
              params: {
                collection: process.env.VUE_APP_DEGENPUNKS_ADDR,
                id: this.tokenId
              }
            })
          }, (error) => {
            this.processing = false
            this.globalError = error
            console.log(error)
          });
        })
        .catch((error) => {
          this.globalError = "Invalid recipient address"
          this.processing = false
          console.log(error)
        });
    },
    buyToken () {
      this.processing = true;

      const signer = marketplace.connect(this.web3Modal.signer)
      console.log(this.item.current_asking_price)
      signer["buyItem"](this.item.collection_address, this.item.token_id, {value: this.item.current_asking_price.toString()})
        .then((tx) => {
          return tx.wait().then(() => {
            console.log(tx)
          }, (error) => {
            this.processing = false
            console.log(error)
          });
        })
        .catch((error)=>{
          this.processing = false
          console.log(error)
          this.globalError = error.data.message
        });
    },
    cancelItemListing () {
      this.processing = true;
      const signer = marketplace.connect(this.web3Modal.signer)
      signer["cancelItemListing"](this.item.collection_address, this.item.token_id, {})
        .then((tx) => {
          return tx.wait().then(() => {
            this.processing = false
          }, (error) => {
            this.globalError = error.data.message
            this.processing = false
          });
        })
        .catch(()=>{
          this.processing = false
        });
    },
    approveToken() {
      this.processing = true;

      const signer = this.collectionContract.connect(this.web3Modal.signer)
      signer["setApprovalForAll"](process.env.VUE_APP_MARKETPLACE_ADDR, true)
        .then((tx) => {
          return tx.wait().then(() => {
            this.processing = false
            this.approved = true
          }, (error) => {
            this.processing = false
            console.log(error)
          });
        })
        .catch((error) => {
          this.globalError = error.data.message
          this.processing = false
          console.log(error)
        });
    },
    listToken(price) {
      this.processing = true;

      price = this.web3Modal.web3.utils.toWei(price.toString())

      const signer = marketplace.connect(this.web3Modal.signer)
      console.log(signer)
      console.log(this.item.collection_address)
      signer["createItemListing"](this.item.collection_address, this.item.token_id, price, 1695317649)
        .then((tx) => {
          return tx.wait().then((receipt) => {
            console.log(receipt)
          }, (error) => {
            this.processing = false
            console.log(error)
          });
        })
        .catch((error) => {
          this.globalError = error.data.message
          this.processing = false
          console.log(error)
        });
    }
  },
  data() {
    return {
      item: null,
      collection: null,
      processing: false,
      approved: false,
      wrappedNativeAllowance: new Web3.utils.toBN(0),
      collectionContract: null,
      globalError: "",
      history: []
    }
  },
  async beforeCreate() {
    try {
      const response = await axios.get(process.env.VUE_APP_API_URL + "/collections/" + this.$route.params.collection + "/items/" + this.$route.params.tokenId + "/");
      this.item = response.data
      this.collectionContract = getERC721Contract(this.item.collection_address)
      if (this.$store.getters.currentAccount) {
        this.approved = await this.collectionContract['isApprovedForAll'](this.$store.getters.currentAccount, process.env.VUE_APP_MARKETPLACE_ADDR)
        this.wrappedNativeAllowance = await wrappedNative['allowance'](this.$store.getters.currentAccount, process.env.VUE_APP_MARKETPLACE_ADDR)
      }
    } catch (err) {
      console.error(err);
    }
  },
  watch: {
    '$store.state.web3Modal.account': async function () {
      this.approved = await this.collectionContract['isApprovedForAll'](this.$store.getters.currentAccount, process.env.VUE_APP_MARKETPLACE_ADDR)
      this.wrappedNativeAllowance = await wrappedNative['allowance'](this.$store.getters.currentAccount, process.env.VUE_APP_MARKETPLACE_ADDR)
    }
  },
  async mounted() {
    axios
      .get(process.env.VUE_APP_API_URL + "/collections/" + this.$route.params.collection + "/items/" + this.$route.params.tokenId + "/history/")
      .then(response => {
        this.history = response.data.results
      })

    axios
      .get(process.env.VUE_APP_API_URL + "/collections/" + this.$route.params.collection + "/")
      .then(response => {
        this.collection = response.data
      })
  }
}
</script>
