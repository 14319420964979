<template>
  <div class="modal fade" id="modal-list" tabindex="-1"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title fw-bold">List item for sale</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

          <template v-if="approved">
            <div class="modal-body">
              <div class="mb-3">
                <label class="form-label fs-6">Set price in BNB</label>
                <input type="text" class="form-control" v-model="price" id="placebid">
                <small id="modal-transfer-error" class="text_min">{{ globalError }}</small>
              </div>
            </div>

            <div class="modal-footer text-center justify-content-center">
              <span class="d-flex justify-content-center w-100"><strong>Please double check listing price.</strong></span>

              <button class="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2" id="modal-btn-transfer" type="button" @click="listToken()">
                <template v-if="!processing">List Item</template>
                <template v-else><div class="ld ld-ring ld-spin"></div></template>
              </button>
            </div>
          </template>

          <template v-else>
            <div class="modal-body">
              You need to approve marketplace contract before listing.
              <button class="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2" id="modal-btn-approve" type="button" @click="approve()">
                <template v-if="!processing">Approve</template>
                <template v-else><div class="ld ld-ring ld-spin"></div></template>
              </button>
            </div>

            <div class="modal-footer text-center justify-content-center">
            </div>
          </template>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalList',
  data () {
    return {
      price: 0.10,
    }
  },
  props: {
    processing: Boolean,
    globalError: String,
    approved: Boolean
  },
  methods: {
    listToken() {
      this.$emit('listToken', this.price)
    },
    approve() {
      this.$emit('approveToken')
    }
  }
}
</script>
