import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Web3 from 'web3'
import rr from './utils/rr'
import moment from 'moment-timezone'


Vue.config.productionTip = false

Vue.filter('toBNB', function (value, digits= 0) {
  if (value) {
    const priceStr = value.toString()
    const price = Web3.utils.fromWei(priceStr)
    if (!digits) {
      return price
    }
    return parseFloat(price).toFixed(digits)
  } else {
    return value
  }
})

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment.unix(value).format("DD.MM.YYYY HH:mm");
  }
})

Vue.filter('toShortAddr', function (value) {
  if (value) {
    value = value.substr(0, 5) + "..." + value.substr(38, 4)
  }
  return value
})

Vue.filter('ipfs', function (value) {
  if (value) {
    value = value.replace("https://ipfs.io/ipfs/", process.env.VUE_APP_IPFS_GLOBAL_URL)
    return value.replace('ipfs://', process.env.VUE_APP_IPFS_GLOBAL_URL)
  } else {
    return value
  }
})

Vue.filter('rr', function (value, key='rarity') {
  if (value) {
    return rr[Number(value)][key]
  } else {
    return {'rank': 'Unknown', 'rarity': 'Unknown'}
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

