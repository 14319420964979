<template>
  <main class="main">
    <div class="section pb-0">
      <div class="container">
        <div class="row g-3 my-5">
          <div class="col-lg-12 col-xl-12">
            <div class="row g-0 justify-content-end mb-5 border-bottom pb-3">
              <div class="col-lg-9">
                <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Filter</button>
                <div class="dropdown-menu" style="width: 200px;">
                  <div class="card-body">
                    <div class="filter">
                      <ul class="menu-list">
                        <li class="fw-bold">NFT State</li>
                        <li><hr class="dropdown-divider"></li>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="buynow">
                            <label class="form-check-label" for="buynow">Buy Now</label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="onauction">
                            <label class="form-check-label" for="onauction">On Auction</label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="genesisnftss">
                            <label class="form-check-label" for="genesisnftss">Genesis NFTs</label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="sold">
                            <label class="form-check-label" for="sold">Sold</label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="hasoffers">
                            <label class="form-check-label" for="hasoffers">Has Offers</label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="hassplits">
                            <label class="form-check-label" for="hassplits">Has Splits</label>
                          </div>
                        </li>
                        <li class="fw-bold">Categories</li>
                        <li><hr class="dropdown-divider"></li>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="art">
                            <label class="form-check-label" for="art">Art</label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="Music">
                            <label class="form-check-label" for="Music">Music</label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="SportsGames">
                            <label class="form-check-label" for="SportsGames">Sports/Games</label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="Utility">
                            <label class="form-check-label" for="Utility">Utility</label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="Brands">
                            <label class="form-check-label" for="Brands">Brands</label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="Influencers">
                            <label class="form-check-label" for="Influencers">Influencers</label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="Virtualworld">
                            <label class="form-check-label" for="Virtualworld">Virtual world</label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="Memes">
                            <label class="form-check-label" for="Memes">Memes</label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="Photography">
                            <label class="form-check-label" for="Photography">Photography</label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="Tradingcards">
                            <label class="form-check-label" for="Tradingcards">Trading cards</label>
                          </div>
                        </li>
                        <li class="fw-bold">Market </li>
                        <li><hr class="dropdown-divider"></li>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="primary">
                            <label class="form-check-label" for="primary">Primary</label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="">
                            <label class="form-check-label">secondary</label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="input-group">
                  <span class="input-group-text">Sort By</span>
                  <select class="form-select" aria-label="Default select example">
                    <option selected>Date Listed: Newest</option>
                    <option value="1">Date Listed: Oldest</option>
                    <option value="2">Price: Highest</option>
                    <option value="3">Price: Lowest</option>
                    <option value="4">Date Sold: Newest</option>
                    <option value="5">Date Sold: Oldest</option>
                    <option value="6">Price: Lowest</option>
                    <option value="7">Auction Ending Soon</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row g-3 row-deck box-set">

              <div v-for="(item) in items" v-bind:key="item.id" class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <router-link :to="{ name: 'CollectionItem', params: { collection: item.collection_id, tokenId:item.token_id }}" class="w-100">
                  <div class="card-product-style border border-primary rounded p-3">
                    <div class="card-product-image position-relative">
                      <img class="img-fluid rounded-3 w-100 collection-image" :src="item.image_file" alt="title">
                    </div>
                    <div class="card-product-content pt-3 mt-3 border-top">
                          <span class="card-product-collection">
                            <router-link :to="{ name: 'Collection', params: { collection: item.collection_id }}" class="d-flex align-items-center mb-2">
                              {{ item.collection_name }}
                              <span v-if="item.collection_verified" class="ms-2"><i class="bi bi-check-circle-fill color-light-success"></i></span>
                            </router-link>
                          </span>
                      <h5 class="mb-2">{{item.metadata.name}}</h5>
                      <div class="card-porduct-price d-flex align-items-center">
                        <p class="d-flex align-items-center mb-2">
                          <span v-if="item.current_asking_price">{{item.current_asking_price|toBNB}} BNB</span>
                          <span v-if="item.highest_offer" class="text-muted" style="margin-left:5px;">{{item.highest_offer|toBNB}} BNB</span>
                          <span>&nbsp;</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </main>
</template>

<script>
  import Web3ModalVue from "web3modal-vue";
  import {mapState} from "vuex";
  import axios from "axios";


  export default {
    name: "Discover",
    components: {
      Web3ModalVue
    },
    computed: {
      ...mapState(['web3Modal'])
    },
    data () {
      return {
        items: []
      }
    },
    async mounted() {
      axios
        .get(process.env.VUE_APP_API_URL + "/marketplace/" + process.env.VUE_APP_MARKETPLACE_ID + "/items/")
        .then(response => {
          this.items = response.data.results
        })

    }
  }
</script>
