<template>
  <main class="main">
    <div class="section pb-0">
      <div class="container">
        <div class="row g-3 my-5">
          <div class="col-12">
            <div class="card mb-1">
              <div class="card-body p-4">
                <div class="d-flex align-items-center flex-column flex-md-row">
                  <img src="@/assets/images/author/avatar4.svg" alt="" class="rounded-circle avatar xxl border border-dark">
                  <div class="media-body ms-md-5 mt-4 mt-md-0 mb-2 text-md-start text-center flex-fill">
                    <h3 class="fw-bold mb-1">{{ this.$store.getters.currentAccount }}</h3>
                    <div class="text-muted mb-1">{{ this.$store.getters.getAddressShort }}</div>
                    <div class="mb-3">
                      <p></p>
                    </div>
                  </div>
                  <div class="d-flex flex-column ps-3 ps-md-5">
                    <div class="d-flex mb-2">
                      <button class="btn btn-outline-primary rounded-pill py-2 px-5 ct-btn my-2 w-100">Share</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-5">
            <ul class="nav nav-tabs tab-card justify-content-center">
              <li class="nav-item">
                <a class="nav-link lead color-900 active" data-bs-toggle="tab" href="#Collected">
                  <img src="@/assets/images/svg/cart.svg" alt="">
                  Collected
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link lead color-900" data-bs-toggle="tab" href="#OffersReceived">
                  <img src="@/assets/images/svg/circle_down.svg" alt="">
                  Offers Received
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link lead color-900" data-bs-toggle="tab" href="#OffersMade">
                    <img src="@/assets/images/svg/circle_up.svg" alt="">
                  Offers Made
                </a>
              </li>
            </ul>
            <div class="tab-content mt-5">
              <div class="tab-pane active show" id="Collected">
                <div class="row g-3">

                  <div v-for="(item) in collected" v-bind:key="item.id" class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <router-link :to="{ name: 'CollectionItem', params: { collection: item.collection_id, tokenId:item.token_id }}">
                      <div class="card-product-style border border-primary rounded p-3">
                        <div class="card-product-image position-relative">
                          <img class="img-fluid rounded-3 w-100" :src="item.image_file" alt="title">
                        </div>
                        <div class="card-product-content pt-3 mt-3 border-top">
                          <router-link :to="{ name: 'Collection', params: { collection: item.collection_id }}" class="card-product-collection">
                            <p class="d-flex align-items-center mb-2">
                              {{item.collection_name}}
                              <span class="ms-2" v-if="item.collection_verified"><i class="bi bi-check-circle-fill color-light-success"></i></span>
                            </p>
                          </router-link>
                          <h5 class="mb-2"><a href="bid.html">{{item.metadata.name}}</a></h5>
                        </div>
                      </div>
                    </router-link>
                  </div>


                </div>
              </div>
              <div class="tab-pane" id="OffersReceived">
                <div class="row g-3">
                  <div class="col-lg-12">
                    <table  class="myProjectTable table table-hover custom-table align-middle mb-0" style="width:100%">
                      <thead>
                      <tr>
                        <th>NFT</th>
                        <th>Offered Price</th>
                        <th>From</th>
                        <th>Received On</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td><a href="bid.html" title=""><img src="@/assets/images/product/4.jpg" alt="" class="img-fluid avatar mx-1 rounded-2"> Fresh Tea</a></td>
                        <td><span class="color-price-up">40 WRX (27.96 USD)</span></td>
                        <td> THEUNKNOWNSPIRIT</td>
                        <td> 6 hours ago</td>
                      </tr>
                      <tr>
                        <td><a href="bid.html" title=""><img src="@/assets/images/product/5.jpg" alt="" class="img-fluid avatar mx-1 rounded-2"> Mango Art</a></td>
                        <td><span class="color-price-up">0.02 BNB (8.21 USD)</span></td>
                        <td> yogidelic</td>
                        <td> 7 hours ago</td>
                      </tr>
                      <tr>
                        <td><a href="bid.html" title=""><img src="@/assets/images/product/6.jpg" alt="" class="img-fluid avatar mx-1 rounded-2"> Ink Duck</a></td>
                        <td><span class="color-price-up">13 WRX (9.09 USD)</span></td>
                        <td> majestic</td>
                        <td> 4 days ago</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="OffersMade">
                <div class="row g-3">
                  <div class="col-lg-12">
                    <table  class="myProjectTable table table-hover custom-table align-middle mb-0" style="width:100%">
                      <thead>
                      <tr>
                        <th>NFT</th>
                        <th>Offered Price</th>
                        <th>Made On</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td><a href="bid.html" title=""><img src="@/assets/images/product/8.jpg" alt="" class="img-fluid avatar mx-1 rounded-2"> Neon Paint</a></td>
                        <td> <span class="color-price-down">40 WRX (27.96 USD)</span></td>
                        <td> 6 hours ago</td>
                      </tr>
                      <tr>
                        <td><a href="bid.html" title=""><img src="@/assets/images/product/1.jpg" alt="" class="img-fluid avatar mx-1 rounded-2"> Human Art</a></td>
                        <td> <span class="color-price-down">0.02 BNB (8.21 USD)</span></td>
                        <td> 7 hours ago</td>
                      </tr>
                      <tr>
                        <td><a href="bid.html" title=""><img src="@/assets/images/product/3.jpg" alt="" class="img-fluid avatar mx-1 rounded-2"> Stone Graphy</a></td>
                        <td> <span class="color-price-down">13 WRX (9.09 USD)</span></td>
                        <td> 4 days ago</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import Web3ModalVue from "web3modal-vue";
  import {mapState} from "vuex";
  import {getMarketplaceContract} from "@/utils/contract";
  import axios from "axios";

  const marketplace = getMarketplaceContract()

  export default {
    name: "Profile",
    components: {
      Web3ModalVue
    },
    computed: {
      ...mapState(['web3Modal'])
    },
    methods: {
      getCollected(address) {
        axios
          .get(process.env.VUE_APP_API_URL + "/users/" + address + "/collected/")
          .then(response => {
            this.collected = response.data.results
          })
      }
    },
    data () {
      return {
        collected: []
      }
    },
    async mounted() {
      let vm = this;

      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'setAccount') {
          vm.getCollected(state.web3Modal.account)
        }
      })

      if(this.$store.getters.currentAccount) {
        vm.getCollected(this.$store.getters.currentAccount)
      }
    }
  }
</script>
