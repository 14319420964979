import erc721Abi from '../config/abi/ERC721.json'
import erc20Abi from '../config/abi/ERC20.json'
import marketplaceAbi from '../config/abi/MarketPlaceV1.json'
import {ethers} from "ethers"
import {simpleRpcProvider} from "@/utils/web3"


export const getERC721Contract = (address) => {
  return getContract(erc721Abi, address)
}

export const getERC20Contract = (address) => {
  return getContract(erc20Abi, address)
}

export const getMarketplaceContract = () => {
  return getContract(marketplaceAbi, process.env.VUE_APP_MARKETPLACE_ADDR)
}

export const getWrappedNativeContract = () => {
  return getERC20Contract(process.env.VUE_APP_WRAPPED_NATIVE_ADDR)
}

export const getTestingNftContract = () => {
  return getContract(erc721Abi, '0x6B91214f3aBfB6A350a3e477D324b4173A15A887')
}


const getContract = (abi, address, signer = null) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}
