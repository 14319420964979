<template>
  <!-- main content -->
  <main class="main">
    <div class="section pb-0" v-if="collection">
      <div class="container">
        <div class="row g-3 my-5">
          <div class="col-12">
            <div class="card mb-1">
              <div class="card-body p-4">
                <div class="d-flex align-items-center flex-column flex-md-row">
                  <img :src="collection.thumbnail" alt="" class="rounded-circle avatar xxl border border-dark">
                  <div class="media-body ms-md-4 mt-4 mt-md-0 mb-2 text-md-start text-center flex-fill">
                    <h3 class="fw-bold mb-1">
                      {{collection.name}}
                      <span v-if="collection.verified" class="ms-2"><i class="bi bi-check-circle-fill color-light-success"></i></span>
                    </h3>
                    <div class="text-muted mb-1">Created by @admin</div>
                    <div class="mb-3">
                      <p>{{collection.description}}</p>
                    </div>
                    <div class="social-share d-flex justify-content-center justify-content-md-start align-items-center mt-3">
                      <a class="d-flex justify-content-center align-items-center border me-2 avatar rounded-circle border-primary" href="https://www.instagram.com/">
                        <i class="bi bi-instagram"></i>
                      </a>
                      <a class="d-flex justify-content-center align-items-center border me-2 avatar rounded-circle border-primary" href="https://www.twitter.com/">
                        <i class="bi bi-twitter"></i>
                      </a>
                      <a class="d-flex justify-content-center align-items-center border me-2 avatar rounded-circle border-primary" href="https://www.facebook.com/">
                        <i class="bi bi-facebook"></i>
                      </a>
                    </div>
                  </div>
                  <div class="d-flex flex-column ps-2 ps-md-5 border-start">
                    <div class="row g-3 row-cols-2">
                      <div class="col">
                        <span class="me-2">{{collection.total_items}}</span>
                        <div class="fw-bold mb-0">Items</div>
                      </div>
                      <div class="col">
                        <span v-if="collection.floor" class="me-2">{{collection.floor.current_asking_price__min || 0 | toBNB}} BNB</span>
                        <div class="fw-bold mb-0">Floor Price</div>
                      </div>
                      <div class="col">
                        <span class="me-2">{{collection.owners}}</span>
                        <div class="fw-bold mb-0">Owners</div>
                      </div>
                      <div class="col">
                        <span class="me-2">{{collection.volume | toBNB}} BNB</span>
                        <div class="fw-bold mb-0">Volume Traded</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-5">
            <ul class="nav nav-tabs tab-card justify-content-center" role="tablist">
              <li class="nav-item">
                <a class="nav-link active show lead color-900" data-bs-toggle="tab" href="#Items" role="tab">
                  <img src="@/assets/images/svg/items.svg" alt="">
                  Items
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link lead color-900" data-bs-toggle="tab" href="#activity" role="tab">
                  <img src="@/assets/images/svg/activity.svg" alt="">
                  Activity
                </a>
              </li>
            </ul>
            <div class="tab-content mt-5">
              <div class="tab-pane active show" id="Items">
                <div class="row g-3">

                  <div v-for="(item) in items" v-bind:key="item.token_id" class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <router-link :to="{ name: 'CollectionItem', params: { collection: item.collection_id, tokenId:item.token_id }}">
                      <div class="card-product-style border border-primary rounded p-3">
                        <div class="card-product-image position-relative">
                          <img class="img-fluid rounded-3 w-100 collection-image" :src="item.image_file" alt="title">
                        </div>
                        <div class="card-product-content pt-3 mt-3 border-top">
                          <span class="card-product-collection">
                            <router-link :to="{ name: 'Collection', params: { collection: item.collection_id }}" class="d-flex align-items-center mb-2">
                              {{ item.collection_name }}
                              <span class="ms-2"><i class="bi bi-check-circle-fill color-light-success"></i></span>
                            </router-link>
                          </span>
                          <h5 class="mb-2">{{item.metadata.name}}</h5>
                          <div class="card-porduct-price d-flex align-items-center">
                            <p class="d-flex align-items-center mb-2">
                              <span v-if="item.current_asking_price">{{item.current_asking_price|toBNB}} BNB</span>
                              <span v-if="item.highest_offer" class="text-muted" style="margin-left:5px;">{{item.highest_offer|toBNB}} BNB</span>
                              <span>&nbsp;</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>

                </div>
              </div>
              <div class="tab-pane" id="activity">
                <div class="row g-3">
                  <div class="col-lg-12">
                    <div class="row g-3">
                      <div class="col-xl-12">
                        <div>Avg. Price</div>
                        <h3>0.7719 BNB</h3>
                        <div class="d-flex my-3 flex-wrap">
                          <div style="display: none;">
                            <div class="text-uppercase text-muted small">Percentage</div>
                            <h5 class="color-price-up">+2.61%</h5>
                          </div>
                          <div class="px-4">
                            <div class="text-uppercase text-muted small">Period</div>
                            <h5>All time</h5>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-12" style="display: none;">
                        <div class="toolbar">
                          <button class="btn btn-sm btn-outline-secondary" id="one_month">1M</button>
                          <button class="btn btn-sm btn-outline-secondary" id="six_months">6M</button>
                          <button class="btn btn-sm btn-secondary" id="one_year">1Y</button>
                          <button class="btn btn-sm btn-outline-secondary" id="ytd">YTD</button>
                          <button class="btn btn-sm btn-outline-secondary" id="all">ALL</button>
                        </div>
                        <div class="mt-3 ac-line-transparent" id="apex-CoinChart"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="card mt-4">
                      <div class="card-body">
                        <table class="myProjectTable table table-hover custom-table align-middle mb-0" style="width:100%">
                          <thead>
                          <tr>
                            <th>Events</th>
                            <th>Item</th>
                            <th>Price</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Time</th>
                          </tr>
                          </thead>
                          <tbody>

                          <template v-for="(event) in history">
                            <tr v-bind:key="event.id">
                              <template v-if="event.event_type == 'ItemListingCreated'">
                                <td>
                                  <img src="@/assets/images/svg/list.svg" alt="">
                                  <span class="ps-2">{{event.event_type_display}}</span>
                                </td>
                                <td>
                                  <router-link :to="{ name: 'CollectionItem', params: { collection: event.item.collection_id, tokenId:event.item.token_id }}">
                                    <img :src="event.item.image_file" alt="" class="img-fluid avatar mx-1 rounded-2"> {{event.item.metadata.name}}
                                  </router-link>
                                </td>
                                <td><span>{{event.event_data.args.price | toBNB}} BNB</span></td>
                                <td><span class="color-price-up">{{event.event_data.args.seller | toShortAddr}}</span></td>
                                <td>---</td>
                                <td>
                                  <vue-moments-ago class="collection-activity-ago" prefix="" suffix="ago" :date="event.network_timestamp_datetime" lang="en" />
                                </td>
                              </template>
                              <template v-else-if="event.event_type == 'OfferCreated'">
                                <td>
                                  <img src="@/assets/images/svg/offer.svg" alt="">
                                  <span class="ps-2">{{event.event_type_display}}</span>
                                </td>
                                <td>
                                  <router-link :to="{ name: 'CollectionItem', params: { collection: event.item.collection_id, tokenId:event.item.token_id }}">
                                    <img :src="event.item.image_file" alt="" class="img-fluid avatar mx-1 rounded-2"> {{event.item.metadata.name}}
                                  </router-link>
                                </td>
                                <td><span>{{event.event_data.args.offerAmount | toBNB}} BNB</span></td>
                                <td><span class="color-price-up">{{event.event_data.args.seller | toShortAddr}}</span></td>
                                <td>---</td>
                                <td>
                                  <vue-moments-ago class="collection-activity-ago" prefix="" suffix="ago" :date="event.network_timestamp_datetime" lang="en" />
                                </td>
                              </template>
                              <template v-else-if="event.event_type == 'Transfer'">
                                <td>
                                  <img src="@/assets/images/svg/transfer.svg" alt="">
                                  <span class="ps-2" v-if="event.event_data.args.from != '0x0000000000000000000000000000000000000000'">{{event.event_type_display}}</span>
                                  <span class="ps-2" v-else>Minted</span>
                                </td>
                                <td>
                                  <router-link :to="{ name: 'CollectionItem', params: { collection: event.item.collection_id, tokenId:event.item.token_id }}">
                                    <img :src="event.item.image_file" alt="" class="img-fluid avatar mx-1 rounded-2"> {{event.item.metadata.name}}
                                  </router-link>
                                </td>
                                <td>---</td>
                                <td><span class="color-price-up">{{event.event_data.args.from | toShortAddr}}</span></td>
                                <td>{{event.event_data.args.to | toShortAddr}}</td>
                                <td>
                                  <vue-moments-ago class="collection-activity-ago" prefix="" suffix="ago" :date="event.network_timestamp_datetime" lang="en" />
                                </td>
                              </template>
                              <template v-else-if="event.event_type === 'ItemSold'">
                                <td>
                                  <img src="@/assets/images/svg/cart.svg" alt="">
                                  <span class="ps-2">{{event.event_type_display}}</span>
                                </td>
                                <td>
                                  <router-link :to="{ name: 'CollectionItem', params: { collection: event.item.collection_id, tokenId:event.item.token_id }}">
                                    <img :src="event.item.image_file" alt="" class="img-fluid avatar mx-1 rounded-2"> {{event.item.metadata.name}}
                                  </router-link>
                                </td>
                                <td><span>{{event.event_data.args.price | toBNB}} BNB</span></td>
                                <td><span class="color-price-up">{{event.event_data.args.seller | toShortAddr}}</span></td>
                                <td>{{event.event_data.args.buyer | toShortAddr}}</td>
                                <td>
                                  <vue-moments-ago class="collection-activity-ago" prefix="" suffix="ago" :date="event.network_timestamp_datetime" lang="en" />
                                </td>
                              </template>
                              <template v-else-if="event.event_type == 'OfferCreated'"><td>{{event.event_type_display}}</td></template>
                              <template v-else-if="event.event_type == 'OfferAccepted'"><td>{{event.event_type_display}}</td></template>
                              <template v-else-if="event.event_type == 'AuctionCreated'"><td>{{event.event_type_display}}</td></template>
                              <template v-else-if="event.event_type == 'AuctionBidMade'"><td>{{event.event_type_display}}</td></template>
                              <template v-else-if="event.event_type == 'AuctionSucceeded'"><td>{{event.event_type_display}}</td></template>
                              <!--<template v-else><td>Unknown event: {{event.event_type_display}}</td></template>-->
                            </tr>
                          </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <!-- end main content -->
</template>


<script>
import VueMomentsAgo from 'vue-moments-ago'
import {mapState} from "vuex";
import axios from "axios"


export default {
  name: "Collection",
  components: {
    VueMomentsAgo
  },
  computed: {
    ...mapState(['web3Modal'])
  },
  methods: {
  },
  data () {
    return {
      collection: {'thumbnail': '', 'name': ''},
      history: [],
      items: []
    }
  },
  async beforeCreate () {
  },
  mounted () {
    axios
      .get(process.env.VUE_APP_API_URL + "/collections/" + this.$route.params.collection + "/")
      .then(response => (this.collection = response.data))

    axios
      .get(process.env.VUE_APP_API_URL + "/collections/" + this.$route.params.collection + "/items/")
      .then(response => {
        this.items = response.data.results
      })

    axios
      .get(process.env.VUE_APP_API_URL + "/collections/" + this.$route.params.collection + "/history/")
      .then(response => {
        this.history = response.data.results
      })

  }
}
</script>
