<template>
  <main class="main">
    <div class="section pb-0">
      <div class="container">
        <div class="row g-3 my-5">
          <div class="col-lg-12 col-xl-12">
            <div class="row g-0 justify-content-end mb-5 border-bottom pb-3">
              <div class="col-lg-9">
                <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Filter</button>
                <div class="dropdown-menu" style="width: 200px;">
                  <div class="card-body">
                    <div class="filter">
                      <ul class="menu-list">
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="verifiedonly">
                            <label class="form-check-label" for="verifiedonly">Verified Only</label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="input-group">
                  <span class="input-group-text">Sort By</span>
                  <select class="form-select" aria-label="Default select example">
                    <option selected>Most Spent</option>
                    <option value="1">Most Recent</option>
                    <option value="2">Most Collections</option>
                    <option value="3">Most Followed</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row g-3">

              <div v-for="(collection) in collections" v-bind:key="collection.id" class="col-sm-6 col-md-6 col-lg-6 col-xl-3">
                <router-link :to="{ name: 'Collection', params: { collection: collection.id }}">
                  <div class="card-creator-style border border-primary rounded p-3">
                    <div class="card-creator-image position-relative mb-2">
                      <img class="rounded object-cover w-100" :src="collection.header" alt="title" style="height: 8rem;">
                    </div>
                    <div class="card-creator-intro position-relative mb-3">
                      <h5 class="my-3">
                        {{collection.name}}
                        <span class="ms-2" v-if="collection.verified"><i class="bi bi-check-circle-fill color-light-success"></i></span>
                      </h5>
                    </div>
                    <div class="card-creator-info d-flex justify-content-between align-items-center mb-2">
                      <div class="text-center">
                        <h6 class="mb-0">{{collection.floor.current_asking_price__min || 0 |toBNB}} BNB</h6>
                        <p class="small text-muted mb-0">Floor</p>
                      </div>
                      <div class="text-center">
                        <h6 class="mb-0">{{collection.total_items}}</h6>
                        <p class="small text-muted mb-0">Items</p>
                      </div>
                      <div class="text-center">
                        <h5 class="d-flex align-items-center mb-0">
                          {{collection.volume | toBNB}} BNB
                        </h5>
                        <p class="small text-muted mb-0">Volume</p>
                      </div>
                    </div>
                    <p class="text-muted mb-0">{{collection.description}}</p>
                  </div>
                </router-link>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>


<script>
import {mapState} from "vuex";
import axios from "axios"


export default {
  name: "Collections",
  components: {
  },
  computed: {
    ...mapState(['web3Modal'])
  },
  data() {
    return {
      collections: [],
    }
  },
  async mounted() {
    axios
      .get(process.env.VUE_APP_API_URL + "/collections/")
      .then(response => {
        this.collections = response.data.results
      })

  }
}
</script>
