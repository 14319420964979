import {ethers} from "ethers"
import axios from "axios"
import Web3 from "web3";
import Vuex from 'vuex'
import Vue from 'vue'

import {getWeb3NoAccount} from "@/utils/web3";

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    web3Modal: {
      web3Modal: null,
      web3: getWeb3NoAccount(),
      active: false,
      account: null,
      chainId: 0,
      bnbBalance: 0,
      avatar: "",
      rewards: 0,
      signer: null
    }
  },
  getters: {
    isActive: state => {
      return state.web3Modal.active
    },
    currentAccount: state => {
      if (state.web3Modal.account) {
        return state.web3Modal.account
      } else {
        return "";
      }
    },
    getAddressShort: state => {
      if (state.web3Modal.account) {
        return state.web3Modal.account.substr(0, 5) + "..." + state.web3Modal.account.substr(38, 4);
      } else {
        return "";
      }
    },
    getBnbBalance: state => {
      return state.web3Modal.bnbBalance
    },
    getOwnedTokens: state => {
      return state.web3Modal.ownedTokens
    },
    getListedTokens: state => {
      return state.web3Modal.listedTokens
    },
    getAvatar: state => {
      if (state.web3Modal.ownedTokens.length > 0) {
        return state.web3Modal.ownedTokens[0].image_url
      }
      return state.web3Modal.avatar
    },
    getRewards: state => {
      return state.web3Modal.rewards
    },
    getSigner: state => {
      return state.web3Modal.signer
    },
    getChainId: state => {
      return state.web3Modal.chainId
    },
  },
  mutations: {
    setWeb3Modal(state, web3Modal) {
      state.web3Modal.web3Modal = web3Modal
    },
    setWeb3(state, web3) {
      state.web3Modal.web3 = web3
    },
    setActive(state, active) {
      state.web3Modal.active = active
    },
    setAccount(state, account) {
      state.web3Modal.account = account
    },
    setChainId(state, chainId) {
      state.web3Modal.chainId = chainId
    },
    setBnbBalance(state, bnbBalance) {
      state.web3Modal.bnbBalance = bnbBalance
    },
    setSigner(state, signer) {
      state.web3Modal.signer = signer
    }
  },
  actions: {

    async switchNetwork({state, commit}) {
      const provider = await state.web3Modal.web3Modal.connect();

      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x61' }], // chainId must be in hexadecimal numbers 0x38 (bsc mainnet)
      });
    },
    async connect({state, commit, dispatch}) {
      const provider = await state.web3Modal.web3Modal.connect();
      const ethersProvider = new ethers.providers.Web3Provider(provider);
      const signer = ethersProvider.getSigner()
      const web3 = new Web3(provider)

      commit('setWeb3', web3)
      commit('setSigner', signer)

      const accounts = await web3.eth.getAccounts()
      if (accounts.length > 0) {
        commit('setAccount', accounts[0])
      }
      const chainId = await web3.eth.getChainId()
      commit('setChainId', chainId)
      commit('setActive', true)


      const bnbBalance = await web3.eth.getBalance(accounts[0])
      commit('setBnbBalance', bnbBalance)

      provider.on("connect", async (info) => {
        console.log("connect", info)
      });

      provider.on("accountsChanged", async (accounts) => {
        if (accounts.length > 0) {
          commit('setAccount', accounts[0])
        } else {
          commit('setAccount', null)
          await dispatch('resetApp')
        }
      });
      provider.on("chainChanged", async (chainId) => {
        commit('setChainId', chainId)
      });

    },
    async resetApp({state, commit}) {
      try {
        await state.web3Modal.web3Modal.clearCachedProvider();
      } catch (error) {
        console.error(error)
      }
      commit('setAccount', null)
      commit('setActive', false)
    },
  }
})
