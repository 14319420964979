import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Collection from '../views/Collection'
import Collections from '../views/Collections'
import CollectionItem from '../views/CollectionItem'
import Profile from '../views/Profile'
import Discover from '../views/Discover'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Home
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/discover',
    name: 'Discover',
    component: Discover
  },
  {
    path: '/profile/:tab?',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/collections',
    name: 'Collections',
    component: Collections
  },
  {
    path: '/collection/:collection',
    name: 'Collection',
    component: Collection
  },
  {
    path: '/collection/:collection/:tokenId',
    name: 'CollectionItem',
    component: CollectionItem
  },
]

const router = new VueRouter({
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
