<template>
  <main class="main">

    <!-- Banner -->
    <div class="section hero-area mt-5">
      <div class="container">
        <div class="row g-3 align-items-center position-relative">
          <div class="col-lg-6 order-lg-2 d-flex justify-content-center">
            <img src="@/assets/images/svg/hero.svg" alt="">
          </div>
          <div class="col-lg-6 order-lg-1">
            <div class="hero-content">
              <h1 class="mb-4"><span class="d-block">Create, Collect</span><span class="text-primary">ALL</span> NFTs</h1>
              <p class="mb-4 lead">We offer a wide range of non-fungible tokens, including art, memes<br> and other collectibles</p>
              <div class="d-flex flex-wrap align-items-center">
                <router-link :to="{ name: 'Discover'}" class="btn bg-primary-gradient rounded-pill py-3 px-5 me-2 my-2" title="Explore items">EXPLORE ITEMS </router-link>
                <a v-if="!web3Modal.active" href="#" data-bs-toggle="modal" data-bs-target="#walletconnect" class="btn btn-outline-primary rounded-pill py-3 px-5 ct-btn my-2"> CONNECT WALLET </a>
              </div>
            </div>
          </div>
        </div> <!-- .row end -->
      </div>
    </div>

    <!-- Listings-->
    <div class="section pt-0">
      <div class="container">
        <div class="row mb-5">
          <div class="col-md-12">
            <div class="section-title d-flex justify-content-between align-items-center flex-wrap">
              <div>
                <span class="label-before d-flex position-relative lead align-items-center text-primary">
                   <img src="@/assets/images/svg/listings.svg" alt="">
                   Listings
                </span>
                <h2>Latest Listings</h2>
              </div>
              <router-link :to="{ name: 'Discover'}" class="readmore-btn position-relative py-2 px-3" title="Explore items">
                <span class="lead">VIEW ALL</span>
              </router-link>
            </div>
          </div>
        </div>

        <div class="row g-3 row-deck box-set">

          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3" v-for="listingHistory in recentlyListed" v-bind:key="listingHistory.id">
            <router-link class="w-100" :to="{ name: 'CollectionItem', params: { collection: listingHistory.item.collection_id, tokenId:listingHistory.item.token_id }}">
              <div class="card-product-style border border-primary rounded p-3">
                <div class="card-product-image position-relative">
                  <img class="img-fluid rounded-3 w-100 collection-image" :src="listingHistory.item.image_file" alt="title">
                  <span class="position-absolute bg-white rounded-pill d-flex justify-content-center align-items-center px-2" style="top:10px; left: 5px; font-weight: 600;">
                      <span class="me-1">
                         <img class="img-fluid" src="@/assets/images/clock-icon.png" alt="title">
                      </span>
                      <span id="bidtime1" class="jqclock">
                        <vue-moments-ago prefix="Listed" suffix="ago" :date="listingHistory.network_timestamp_datetime" lang="en" /><br>
                      </span>
                   </span>
                </div>
                <div class="card-product-content pt-3 mt-3 border-top">
                  <router-link :to="{ name: 'Collection', params: { collection: listingHistory.item.collection_id }}" class="card-product-collection mb-2 d-inline-block">
                    <p class="d-flex align-items-center mb-2">
                        {{ listingHistory.item.collection_name }}
                      <span v-if="listingHistory.item.collection_verified" class="ms-2"><i class="bi bi-check-circle-fill color-light-success"></i></span>
                    </p>
                  </router-link>
                  <h5 class="mb-2">{{listingHistory.item.metadata.name}}</h5>
                  <div class="card-porduct-price d-flex align-items-center">
                    <p class="d-flex align-items-center mb-2">
                      {{listingHistory.item.current_asking_price|toBNB}} BNB

                      <span v-if="listingHistory.item.highest_offer" class="text-muted" style="margin-left:5px;">{{listingHistory.item.highest_offer|toBNB}} BNB</span>
                    </p>
                  </div>

                </div>
              </div>
            </router-link>

          </div>

        </div>
      </div>
    </div>


    <!-- COLLECTIONS ITEMS-->
    <div class="section pt-0">
      <div class="container">
        <div class="row mb-5">
          <div class="col-md-12">
            <div class="section-title d-flex justify-content-between align-items-end flex-wrap">
              <div>
                  <span class="label-before d-flex position-relative lead align-items-center text-primary">
                     <img src="@/assets/images/svg/collections.svg" alt="">
                     COLLECTIONS
                  </span>
                <h2>Top Collections</h2>
              </div>
              <router-link :to="{ name: 'Collections'}" class="readmore-btn position-relative py-2 px-3" title="Collections">
                <span class="lead">VIEW ALL</span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="row g-3">

          <div v-for="(collection) in collections" v-bind:key="collection.id" class="col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <router-link :to="{ name: 'Collection', params: { collection: collection.id }}">
              <div class="card-creator-style border border-primary rounded p-3" style="min-height: 335px;">
                <div class="card-creator-image position-relative mb-2">
                  <img class="rounded object-cover w-100" :src="collection.header" alt="title" style="height: 8rem;">
                </div>
                <div class="card-creator-intro position-relative mb-3">
                  <h5 class="my-3">
                    {{collection.name}}
                  </h5>
                </div>
                <div class="card-creator-info d-flex justify-content-between align-items-center mb-2">
                  <div class="text-center">
                    <h6 class="mb-0">{{collection.floor.current_asking_price__min || 0 |toBNB}} BNB</h6>
                    <p class="small text-muted mb-0">Floor</p>
                  </div>
                  <div class="text-center">
                    <h6 class="mb-0">{{collection.total_items}}</h6>
                    <p class="small text-muted mb-0">Items</p>
                  </div>
                  <div class="text-center">
                    <h6 class="d-flex align-items-center mb-0">
                       <span class="me-1">
                          <i class="bi bi-currency-bitcoin"></i>
                       </span>
                      2.5K
                    </h6>
                    <p class="small text-muted mb-0">Sold</p>
                  </div>
                </div>
                <p class="text-muted mb-0">{{collection.description}}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- PROCESS ITEMS-->
    <div class="section py-0">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="section-title d-flex justify-content-between align-items-end flex-wrap">
              <h2>Create and sell your NFTs</h2>
            </div>
            <div class="row g-4 mt-2">
              <div class="col-12 d-flex align-items-center">
                <div class="d-flex align-items-center justify-content-center avatar xl border border-dark rounded-circle">
                  <img src="@/assets/images/svg/wallet.svg" alt="">
                </div>
                <div class="ps-3">
                  <h5 class="mb-0">Set up Your Wallet</h5>
                  <p class="text-muted mb-0">Once you’ve set up your wallet of choice, connect it by clicking the wallet icon in the top right corner.</p>
                </div>
              </div>
              <div class="col-12 d-flex align-items-center">
                <div class="d-flex align-items-center justify-content-center avatar xl border border-dark rounded-circle">
                  <img src="@/assets/images/svg/create_collection.svg" alt="">
                </div>
                <div class="ps-3">
                  <h5 class="mb-0">Create Your Collection</h5>
                  <p class="text-muted mb-0">Crypto collectibles are non-fungible tokens and can be minted on NFT marketplaces.</p>
                </div>
              </div>
              <div class="col-12 d-flex align-items-center">
                <div class="d-flex align-items-center justify-content-center avatar xl border border-dark rounded-circle">
                  <img src="@/assets/images/svg/add_nft.svg" alt="">
                </div>
                <div class="ps-3">
                  <h5 class="mb-0">Add Your NFT</h5>
                  <p class="text-muted mb-0">Upload your work (image, video, audio, or 3D art), add a title and description</p>
                </div>
              </div>
              <div class="col-12 d-flex align-items-center">
                <div class="d-flex align-items-center justify-content-center avatar xl border border-dark rounded-circle">
                  <img src="@/assets/images/svg/list_nft.svg" alt="">
                </div>
                <div class="ps-3">
                  <h5 class="mb-0">List them for sale</h5>
                  <p class="text-muted mb-0">You choose how you want to sell your NFTs, and we help you sell them!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import VueMomentsAgo from 'vue-moments-ago'
import axios from "axios"
import {mapState} from "vuex";

export default {
  name: "Home",
  components: {
    VueMomentsAgo
  },
  data () {
    return {
      collections: [],
      recentlyListed: []
    }
  },
  computed: {
    ...mapState(['web3Modal'])
  },
  methods: {
  },
  beforeCreate() {
  },
  mounted () {
    let vm = this;

    axios
      .get(process.env.VUE_APP_API_URL + "/collections/")
      .then(response => {
        vm.collections = response.data.results
      })

    axios
      .get(process.env.VUE_APP_API_URL + "/marketplace/" + process.env.VUE_APP_MARKETPLACE_ID + "/items/recently_listed/")
      .then(response => {
        this.recentlyListed = response.data.results
      })

  }
}
</script>
