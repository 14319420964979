<template>
  <div id="app">
    <div id="Moshpit" class="theme-orange">

      <!-- Top Header -->
      <nav class="navbar navbar-expand-xl py-lg-2 lp-menu">
        <div class="container py-2 rounded">

          <router-link :to="{ name: 'Home'}" class="navbar-brand pt-2 order-1" title="Moshpit">
            <img src="@/assets/images/svg_source/logo.svg" alt="">
            <span class="fw-bold  fs-5 px-2">Moshpit</span>
          </router-link>

          <div class="d-flex align-items-center order-2 order-md-2 order-xl-3">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 pull-right">
              <li class="nav-item">
                <button
                  class="d-inline-flex align-items-center justify-content-center border border-primary py-2 px-4 rounded-pill ct-btn m-1 pull-right"
                  data-bs-toggle="modal" data-bs-target="#modal-free-mint"
                >
                  MINT TESTING NFT
                </button>
              </li>
            </ul>
          </div>

          <div class="d-flex align-items-center order-2 order-md-2 order-xl-3">
            <form class="d-md-flex d-none align-items-center">
              <div class="input-group mx-2">
                <span class="input-group-text bg-transprant"><i class="fa fa-search"></i></span>
                <input type="search" class="form-control bg-transprant" placeholder="Type to search">
              </div>
            </form>

            <template v-if="web3Modal.active">
              <div class="collapse navbar-collapse order-3 order-md-3 order-xl-2">
                <router-link :to="{ name: 'Profile'}" class="btn text-white bg-primary-gradient rounded-pill d-flex align-items-center justify-content-center mx-2">
                  Profile
                </router-link>
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item align-content-center">
                    {{ this.$store.getters.getAddressShort }}<br>
                    {{ this.$store.getters.getBnbBalance | toBNB }} BNB
                  </li>
                </ul>
              </div>
            </template>
            <template v-else>
              <div class="collapse navbar-collapse order-3 order-md-3 order-xl-2">
                <a @click="connect"  href="#" class="btn text-white bg-primary-gradient rounded-pill d-flex align-items-center justify-content-center mx-2">
                  Connect
                </a>
              </div>
            </template>
          </div>

          <div class="collapse navbar-collapse order-3 order-md-3 order-xl-2" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link :to="{ name: 'Home'}" class="nav-link me-2 lead">Home</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'Discover'}" class="nav-link me-2 lead" title="Collections">Discover</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'Collections'}" class="nav-link me-2 lead" title="Collections">Collections</router-link>
              </li>
            </ul>
          </div>

          </div>
      </nav>

      <router-view></router-view>


      <!-- Footer -->
      <div class="section footer pb-0 mt-5 border-top">
        <div class="container">
          <div class="row g-3 justify-content-between mb-5">
            <div class="col-lg-4 col-md-6 col-12">
              <div class="d-flex align-items-center mb-3">
                <router-link :to="{ name: 'Home'}" class="navbar-brand p-0" title="Moshpit">
                  <img src="@/assets/images/svg_source/logo.svg" alt="">
                  <span class="fw-bold  fs-5 px-2">Moshpit</span>
                </router-link>
              </div>
              <span class="lead">
                We offer a wide range of non-fungible tokens, including art,
                  censorship-resistant domain names and other collectibles</span>
              <div class="social-share d-flex justify-content-start align-items-center mt-3">
                <a class="d-flex justify-content-center align-items-center border me-2 avatar rounded-circle border-primary" href="https://www.instagram.com/">
                  <i class="bi bi-instagram"></i>
                </a>
                <a class="d-flex justify-content-center align-items-center border me-2 avatar rounded-circle border-primary" href="https://www.twitter.com/">
                  <i class="bi bi-twitter"></i>
                </a>
                <a class="d-flex justify-content-center align-items-center border me-2 avatar rounded-circle border-primary" href="https://www.facebook.com/">
                  <i class="bi bi-facebook"></i>
                </a>
                <a class="d-flex justify-content-center align-items-center border me-2 avatar rounded-circle border-primary" href="https://www.linkedin.com/">
                  <i class="bi bi-linkedin"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-7 col-12">
              <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                  <h4 class="mb-4">Marketplace</h4>
                  <ul class="footer-link px-3">
                    <li class="my-2"><a href="">Discover</a></li>
                    <li class="my-2"><a href="">Collections</a></li>
                  </ul>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                  <h4 class="mb-4">Web Links</h4>
                  <ul class="footer-link px-3">
                    <li class="my-2"><a href="">Support</a></li>
                  </ul>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                  <h4 class="mb-4">Other Pages</h4>
                  <ul class="footer-link px-3">
                    <li class="my-2"><a href="">Profile</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom border-top pt-4">
          <div class="container">
            <div class="row">
              <div class="col-12 text-center">
                <p><a href="https://kinos.one" rel="nofollow" target="_blank" class="text-primary">@Kinos1</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <web3-modal-vue
      ref="web3modal"
      :theme="theme"
      :provider-options="providerOptions"
      cache-provider
    />
    <ModalFreeMint @freeMint="freeMint($event)"></ModalFreeMint>

  </div>
</template>

<style>
  @import './assets/css/custom.style.min.css';
  @import './assets/css/moshpit.css';
</style>

<script>
  import jQuery from "jquery";
  import Web3ModalVue from "web3modal-vue";
  import WalletConnectProvider from "@walletconnect/web3-provider";
  import {mapState} from "vuex";
  import ModalFreeMint from "@/components/ModalFreeMint";
  import {getTestingNftContract} from "@/utils/contract";

  const testingNftContract = getTestingNftContract()

  window.jQuery = window.$ = jQuery

  require('./assets/bundles/bootstrap.bundle.min.js')
  require('./assets/bundles/libscripts.bundle.js')
  require('./assets/bundles/countdown.bundle.js')

  export default {
    components: {
      Web3ModalVue,
      ModalFreeMint
    },
    computed: {
      ...mapState(['web3Modal'])
    },
    data() {
      return {
        theme: 'light',
        providerOptions: {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              rpc: {
                56: "https://bsc-dataseed.binance.org/",
                97: "https://data-seed-prebsc-1-s1.binance.org:8545",
              },
              network: 'binance'
            }
          }
        },
      }
    },
    created() {
    },
    mounted() {
      this.$nextTick(async () => {
        console.log(this.$refs)
        const web3modal = this.$refs.web3modal;
        this.$store.commit('setWeb3Modal', web3modal)
        if (web3modal.cacheProvider) {
          await this.$store.dispatch('connect')

          if((this.$store.getters.getChainId !== 97 && this.$store.getters.getChainId !== '0x61') && this.$store.getters.getChainId !== 0) {
            await this.$store.dispatch('switchNetwork')
          }
        } else {
          console.log("Test")
        }
      })
    },
    methods: {
      freeMint () {
        this.processing = true;
        const signer = testingNftContract.connect(this.web3Modal.signer)
        signer["mint"]()
          .then((tx) => {
            return tx.wait().then(() => {
              this.processing = false
            }, (error) => {
              this.globalError = error.data.message
              this.processing = false
            });
          })
          .catch(()=>{
            this.processing = false
          });
      },
      async connect() {
        await this.$store.dispatch('connect')
      },
    }
  }
</script>
