<template>
  <div class="modal fade" id="modal-buy" tabindex="-1"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title fw-bold">Buy {{ item.metadata.name }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <div class="mb-3">
            <h5>Price: {{item.current_asking_price|toBNB}} BNB</h5>
            <small id="modal-transfer-error" class="text_min">{{ globalError }}</small>
          </div>
        </div>

        <div class="modal-footer text-center justify-content-center">
          <span class="d-flex justify-content-center w-100"><strong>Please double check price.</strong></span>

          <button class="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2" id="modal-btn-transfer" type="button" @click="buyToken()">
            <template v-if="!processing">Buy</template>
            <template v-else><div class="ld ld-ring ld-spin"></div></template>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalBuy',

  props: {
    item: Object,
    processing: Boolean,
    globalError: String
  },
  methods: {
    buyToken() {
      this.$emit('buyToken')
    },
  }
}
</script>
